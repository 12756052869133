import React from "react"
import styled from "@emotion/styled"
import { colors } from "../utils/presets"

const Li = styled.li({
  marginBottom: 15,
  position: `relative`,
  left: 20,

  "&:before": {
    content: "''",
    width: `10px`,
    height: `10px`,
    borderRadius: 50,
    background: colors.ORANGE,
    position: `absolute`,
    boxShadow: `0 0 0 5px #f1e2c8, 0 0 5px 0 ${colors.ORANGE}`,
    top: `50%`,
    left: -30,
    transform: `translateY(-50%)`,
  },
})

export default Li
