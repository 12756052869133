import React from "react"
import Layout from "../../layout"
import PagesHero from "../../pages"
import Section from "../../section"
import Li from "../../Li"
import ParallaxContainer from "../../ParallaxContainer"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import PrimaryTitle from "../../PrimaryTitle"
import { FormattedMessage, FormattedHTMLMessage } from "react-intl"

/** @jsx jsx */
import { jsx } from "@emotion/core"
import styled from "@emotion/styled"

import { Parallax } from "react-scroll-parallax"
import map from "../../../images/map.svg"
import BoxCards from "../../boxCard"
const BoxCard = styled.div`
  box-shadow: 0px 5px 15px 0px rgba(130, 136, 147, 0.15);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 20px;
  padding: 70px 40px;
  background: #fff;
`

const OtherServices = props => {
  const data = useStaticQuery(graphql`
    query {
      transporte: file(name: { eq: "transporte" }) {
        childImageSharp {
          fluid(maxWidth: 540, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      boda: file(name: { eq: "bodas" }) {
        childImageSharp {
          fluid(maxWidth: 540, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      eventos: file(name: { eq: "eventos" }) {
        childImageSharp {
          fluid(maxWidth: 540, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      reuniones: file(name: { eq: "reuniones" }) {
        childImageSharp {
          fluid(maxWidth: 540, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      escolar: file(name: { eq: "escolar" }) {
        childImageSharp {
          fluid(maxWidth: 540, quality: 90) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      aboutImg: file(name: { eq: "about-banner2" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Layout location={props.location}>
        <PagesHero>
          <Section>
            <div className="has-text-centered mb-6">
              <PrimaryTitle textId="OtherServices" />
              <FormattedMessage id="ServicesSubText" />
            </div>
            <ParallaxContainer>
              <Parallax y={[-25, 25]} tagOuter="figure">
                <Img
                  alt={`About Us`}
                  fluid={data.aboutImg.childImageSharp.fluid}
                />
              </Parallax>
            </ParallaxContainer>
            <section
              css={{
                position: `relative`,
                top: `-120px`,
              }}
              className={`columns`}
            >
              <div className="column">
                <BoxCard>
                  <ul>
                    <Li>
                      <FormattedMessage id="AirportWelcoming" />
                    </Li>
                    <Li>
                      <FormattedMessage id="BilingualGuide" />
                    </Li>
                    <Li>
                      <FormattedMessage id="CarRental" />
                    </Li>
                    <Li>
                      <FormattedMessage id="CharterServices" />
                    </Li>
                    <Li>
                      <FormattedMessage id="CruisesHandling" />
                    </Li>
                  </ul>
                </BoxCard>
              </div>
              <div className="column">
                <BoxCard>
                  <ul>
                    <Li>
                      <FormattedMessage id="CustomizedItineraries" />
                    </Li>
                    <Li>
                      <FormattedMessage id="DestinationManagement" />
                    </Li>
                    <Li>
                      <FormattedMessage id="DomesticFlights" />
                    </Li>
                    <Li>
                      <FormattedMessage id="HalfandFullTours" />
                    </Li>
                    <Li>
                      <FormattedMessage id="GroupPrograms" />
                    </Li>
                  </ul>
                </BoxCard>
              </div>
              <div className="column">
                <BoxCard>
                  <ul>
                    <Li>
                      <FormattedMessage id="HotelReservation" />
                    </Li>
                    <Li>
                      <FormattedMessage id="IncentivePrograms" />
                    </Li>
                    <Li>
                      <FormattedMessage id="Multidestination" />
                    </Li>
                    <Li>
                      <FormattedMessage id="OrganizationSeminars" />
                    </Li>
                    <Li>
                      <FormattedMessage id="PrivateServices" />
                    </Li>
                  </ul>
                </BoxCard>
              </div>
            </section>
          </Section>
        </PagesHero>
      </Layout>
    </React.Fragment>
  )
}

export default OtherServices
